import { graphql, Link, navigate } from "gatsby";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navigation/Navbar";
import SeoHelmet from "../components/Seo/SeoHelmet";
import MaxContent from "../layout/MaxContent";
import { componentBuilder } from "../helpers/componentsHelper";
import { useDevice } from "../components/Hooks";
import MakeSlug from "../helpers/makeSlug";
import TertiaryHeader from "../components/Headers/TertiaryHeader";
import { GatsbyImage } from "gatsby-plugin-image";

const BackArrow =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-back-arrow.svg";
const LookingGlass =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-looking-glass.svg";

export default (data: any) => {
  const [isMobileView, setIsMobileView] = useState<Boolean>();

  const { isMobile } = useDevice();
  const blogData = data.data.blog;
  const sidebarData = data.data.allStrapiBlog.nodes;

  const navbarComponent = blogData.Navbar;
  const blogContentComponents = blogData.Content;
  const seoComponent = blogData.SEO;
  const footerComponent = blogData.Footer;
  const blogContent = componentBuilder(blogContentComponents);

  const [input, setInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (search !== "") {
      navigate(`/blog/?q=${search.replace(/\s/g, "+")}`);
    }
  }, [search]);

  useEffect(() => {
    const links = document.querySelectorAll(".blog-template a");
    for (var i = 0; i < links.length; i++) {
      // @ts-ignore
      if (links[i].hostname != window.location.hostname) {
        links[i].setAttribute("rel", "nofollow");
        links[i].setAttribute("target", "_blank");
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      const hash = window.location.hash;
      if (hash.includes("#")) {
        console.log("scroll");
        window.scrollBy(0, -130);
      }
    }
  });

  useEffect(() => {
    isMobile ? setIsMobileView(true) : setIsMobileView(false);
  }, []);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearch(input);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleClick = () => {
    setSearch(input);
  };

  return (
    <>
      {/* @ts-ignore */}
      <SeoHelmet data={seoComponent} locale={blogData.locale} />
      {navbarComponent && <Navbar data={navbarComponent} />}

      <TertiaryHeader data={blogData.Header?.Title ? blogData.Header : blogData} />

      <MaxContent size={isMobileView ? "max" : "content"}>
        <div className="grid lg:grid-cols-12 gap-10 my-[80px] blog-template">
          {/* sidebar */}
          <div className="lg:col-span-4 order-last lg:order-first bg-inactive-gray px-[20px] h-min">
            <div className="mt-[30px]">
              <Link to="/blog/" className="no-underline">
                <div className="flex flex-row items-center gap-[15px]">
                  <img
                    src={BackArrow}
                    className="w-[19px] h-[15px]"
                    alt="BackArrow"
                  />
                  <>Blogs</>
                </div>
              </Link>
            </div>

            <div className="relative  mb-[20px] mt-[30px]">
              <input
                className="p-3 border rounded-md"
                type={"text"}
                placeholder="search"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleOnChange(e)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(e)
                }
              />
              <img
                src={LookingGlass}
                className="h-5 absolute right-3 top-1/2 -translate-x-1/2 -translate-y-1/2"
                onChange={(e: any) => setInput(e.target.value)}
                onClick={(e: any) => handleClick()}
                alt="LookingGlass"
              />
            </div>

            <h5 className="h5-default pt-[30px] text-black border-t-[2px] border-t-purple mb-[40px]">
              Derniers articles
            </h5>

            <div className="flex flex-col gap-[25px] mb-[60px]">
              {sidebarData &&
                sidebarData.map((node: any) => {
                  let url = `/blog/${node.CustomUrl ?? MakeSlug(node.Title)}`;

                  if (node.Category) {
                    url = `/blog/${MakeSlug(node.Category)}/${
                      node.CustomUrl ?? MakeSlug(node.Title)
                    }`;
                  }

                  if (node.Category === "Conseil_et_fiches_pratiques") {
                    url = `/blog/conseils/${
                      node.CustomUrl ?? MakeSlug(node.Title)
                    }`;
                  }

                  if (node.Category === "Edito_et_actus") {
                    url = `/blog/edito/${
                      node.CustomUrl ?? MakeSlug(node.Title)
                    }`;
                  }

                  return (
                    <Link
                      key={node.id}
                      to={url.replace("//", "/")}
                      className="no-underline hover:underline hover:text-purple text-black font-normal text-link-sm"
                    >
                      {node.Title}
                    </Link>
                  );
                })}
            </div>
          </div>

          {/* blog content */}
          <div className="lg:col-span-8 overflow-hidden">
            <GatsbyImage
              className="w-full h-[270px] mb-10"
              image={
                blogData.PrimaryImage?.file?.childImageSharp?.gatsbyImageData
              }
              alt={blogData.PrimaryImage?.alternativeText}
            />

            <div className="p-4 md:p-0">
              <div className="flex flex-col gap-[30px] blog-content overflow-hidden">
                {blogContent?.map((block, id) => (
                  <div key={id}>{block}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </MaxContent>

      {footerComponent && <Footer data={footerComponent} />}
    </>
  );
};

export const blogQuery = graphql`
  query ($id: String!) {
    allStrapiBlog(limit: 5) {
      nodes {
        id
        Title
        Category
        CustomUrl
      }
    }
    blog: strapiBlog(id: { eq: $id }) {
      id
      Title
      Category
      Description
      locale
      PrimaryImage {
        file {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Header {
        Title
      }
      SEO {
        SeoTitle
        SeoDescription
        DoNotIndex
        DoNotFollow
        Schema
        CanonicalTag
        Image {
          file {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        Links {
          HrefLang
          Href
        }
      }
      Navbar {
        DropdownLabel
        DropdownLinks {
          Label
          Url
        }
        Links {
          Label
          Url
        }
        Phone
        HideAccount
        AccountLabel
        AccountIcon {
          file {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        AccountUrl
        ButtonLabel
        ButtonUrl
      }
      Footer {
        group_title_1
        group_title_2
        group_title_3
        group_title_4
        group_links_1 {
          Label
          Url
        }
        group_links_2 {
          Label
          Url
        }
        group_links_3 {
          Label
          Url
        }
        group_links_4 {
          Label
          Url
        }
        group_links_bottom {
          Label
          Url
        }
        contact_address
        contact_phone_number
        contact_phone_subtitle
        contact_title
        contact_map_link {
          Label
          Url
          id
        }
        form_header
        form_submit_button_link {
          Label
          Url
        }
        form_text_placeholder
        facebook_url
        instagram_url
        linkedin_url
        twitter_url
      }
      # Dynamic Zone COMPONENT START
      Content {
        __typename
        ... on StrapiComponentBasicText {
          Text
        }
        ... on StrapiComponentBasicTitle {
          Title
        }
        ... on StrapiComponentBasicTitleText {
          Title
          Text
          TitleType
        }
        ... on StrapiComponentBasicButton {
          Label
          Url
          Secondary
        }
        ... on StrapiComponentBasicImage {
          SingleImage {
            file {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        ... on StrapiComponentComponentLegalTable {
          id
          Columns {
            Title
          }
          Rows {
            Text
          }
        }
        ... on StrapiComponentComponentHubspotForm {
          id
          portalId
          formId
          region
        }
        # Dynamic Zone COMPONENT END
      }
    }
  }
`;
